import React, { Fragment } from 'react';
import {
  CallToAction,
  Company,
  CompanyGroup,
  ContactForm,
  Cover,
  Paragraph,
  Picture,
  PressGroup,
  TextKeywords,
  Title, 
} from 'components';

export const builder = (bloc) => {
  switch (bloc.__typename) {
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_CallToAction':
    return <CallToAction bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Company':
    return <Company bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_CompanyGroup':
    return <CompanyGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_ContactForm':
    return <ContactForm bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Cover':
    return <Cover bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Paragraph':
    return <Paragraph bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Picture':
    return <Picture bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_PressGroup':
    return <PressGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Text123':
    return <TextKeywords bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Title':
    return <Title bloc={bloc} />;
  default:
    return <Fragment/>;
  }
}
