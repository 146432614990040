import React, { Fragment, useEffect } from 'react';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import { Layout, SEO } from 'components';
import { builder } from 'utils/blocs';

export default ({ data }) => {
  const { title, seoWidget, pageBuilder } = data.cms.page;
  const { slug } = data.cms.defaultLanguage;
  const cookieName = 'LAELanguage';

  useEffect(() => {
    const currentLanguage = Cookies.get(cookieName);
    const pathLanguage = typeof window !== 'undefined' && window.location.pathname.split('/')[1];
    const isPathLanguage = data.cms.languages.includes(pathLanguage);

    if (!currentLanguage) {
      Cookies.set(cookieName, slug);
    }
    if (currentLanguage !== pathLanguage) {
      Cookies.set(cookieName, isPathLanguage ? pathLanguage : slug);
    }
  });

  return (
    <Layout>
      <SEO title={title} seoWidget={seoWidget} />
      {
        pageBuilder.content && pageBuilder.content.map(({ className, targetId, blocs }, idx) => (
          <div key={idx} id={targetId} className={className}>
            {blocs.map((bloc, blocId) => (
              <Fragment key={blocId}>
                {builder(bloc)}
              </Fragment>
            ))}
          </div>
        ))
      }
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($id: ID!) {
    cms {
      defaultLanguage {
        slug
      }
      languages {
        slug
      }
      page(id: $id) {
        isFrontPage
        title
        seoWidget {
          title
          description
          keywords
          socialImage {
            mediaItemUrl
          }
        }
        pageBuilder {
          content {
            className
            targetId
            blocs {
              ...CallToActionFragment
              ...CompanyFragment
              ...CompanyGroupFragment
              ...ContactFormFragment
              ...CoverFragment
              ...ParagraphFragment
              ...PictureFragment
              ...PressGroupFragment
              ...Text123Fragment
              ...TitleFragment
            }
          }
        }
      }
    }
  }
`;
